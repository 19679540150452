<template>
  <div id="wrapper" style="background-color: white">
    <div v-if="showSchedulePopup" class="popup-overlay">
      <div class="popup-content">
        <button type="button" class="popup-close" @click="closeSchedulePopup">
          <span aria-hidden="true">&times;</span>
        </button>
        <h3 class="popup-title">Connect with Our Team</h3>
        <form @submit.prevent="submitForm" class="popup-form">
          <div class="form-group">
            <label for="name" class="form-label"
              >Name <span style="color: #ff0000c4">*</span></label
            >
            <input
              type="text"
              id="name"
              placeholder="Enter your full name"
              v-model="form.name"
              class="form-input"
            />
            <span v-if="nameError" style="color: #ff0000c4; font-size: 12px">
              Please enter a valid name using only alphabetic characters, with a length
              between 3 and 30 characters.
            </span>
          </div>
          <div class="form-group">
            <label for="email" class="form-label"
              >Email <span style="color: #ff0000c4">*</span></label
            >
            <input
              type="email"
              id="email"
              placeholder="Enter your email"
              v-model="form.email"
              class="form-input"
            />
            <span v-if="emailError" style="color: #ff0000c4; font-size: 12px">
              Please enter a valid email.
            </span>
          </div>
          <div class="form-group">
            <label for="phone" class="form-label"
              >Phone <span style="color: #ff0000c4">*</span></label
            >
            <input
              type="text"
              id="phone"
              placeholder="Enter your phone number"
              v-model="form.phone"
              class="form-input"
            />
            <span v-if="phoneError" style="color: #ff0000c4; font-size: 12px">
              Please enter a valid phone number using only numeric digits, with a length
              between 7 and 16 characters.
            </span>
          </div>
          <button type="submit" class="form-submit" v-if="!isSubmitting">Submit</button>
          <button type="submit" class="form-submit" v-if="isSubmitting">
            Submitting...
          </button>
        </form>
      </div>
    </div>

    <header-component></header-component>
    <div class="loader" v-if="loading"></div>
    <main
      class="content"
      v-if="!loading"
      style="background-color: white; padding-top: 20px"
    >
      <div class="container-fluid">
        <div class="flex-lg-row row flex-column listingDetails">
          <div class="listingDetails__colLeft col-lg-8">
            <div class="backLink" v-if="prevRoute">
              <a @click="$router.go(-1)"
                ><i class="fas fa-angle-double-left"></i> Back to Properties</a
              >
            </div>
            <div class="backLink" v-else>
              <a :href="$router.push('/')"
                ><i class="fas fa-angle-double-left"></i> Back to Home</a
              >
            </div>

            <div class="listingDetails__facilities">
              <agile class="slideshow-container" :slidesToShow="1" :dots="false">
                <div v-if="images.length == 0">
                  <img
                    :src="`${$store.state.common.publicPath}img/no_image1.png`"
                    style="width: 100%"
                  />
                </div>
                <div class="slide" v-else v-for="(img, imgInx) in images" :key="imgInx">
                  <div id="extended-img" ref="wrapper">
                    <div class="numbertext">{{ imgInx + 1 }} / {{ images.length }}</div>
                    <!-- 
                  <div id="spinnerImage">
                    <i class="fas fa-circle-notch fa-spin" style="font-size: 26px; color: #268cab"></i>
                  </div> -->
                    <!-- <img 
                  v-if="!isImageDisplay"
                   ref="image" :src="`${baseAPI}${img.image_name}`" /> 
                   -->
                    <img ref="image" :src="`${baseAPI}${img.image_name}`" />
                  </div>
                </div>
              </agile>

              <h1 v-if="property['title']" style="margin-top: 20px">
                {{ property["title"] || null }}
              </h1>
              <p
                v-if="
                  property['location'] &&
                  property['location'] != '' &&
                  property['location'] != 'null'
                "
              >
                <span style="color: #284858">
                  <i class="fa-solid fa-map-pin"></i> Address:
                </span>
                {{ property["location"] }}
                {{
                  property["state"] &&
                  property["state"] != "" &&
                  property["state"] != "null"
                    ? property["state"]
                    : null
                }},
                {{
                  property["pincode"] &&
                  property["pincode"] != "" &&
                  property["pincode"] != "null"
                    ? property["pincode"]
                    : null
                }}
              </p>
              <p
                v-if="
                  property['property_description'] != '' &&
                  property['property_description'] != null &&
                  property['property_description'] != 'null'
                "
              >
                <!-- <i class="fa-solid fa-file-pen"></i>    -->
                <!-- Description : -->
                {{
                  property["property_description"] != "" &&
                  property["property_description"] != null &&
                  property["property_description"] != "null"
                    ? property["property_description"]
                    : null
                }}
              </p>
              <div class="row-jet">
                <p
                  v-if="
                    property['property_class'] &&
                    property['property_class'] != '' &&
                    property['property_class'] != 'null'
                  "
                >
                  <span style="color: #284858">
                    <i class="fa-solid fa-building"></i> Class type:
                  </span>
                  {{ property["property_class"] }}
                </p>

                <p
                  v-if="
                    property['building_size'] != 0 &&
                    property['building_size'] != null &&
                    property['building_size'] != '' &&
                    property['building_size'] != 'null'
                  "
                >
                  <span style="color: #284858">
                    <i class="fa-solid fa-building"></i> Building Size (sq):
                  </span>
                  {{ property["building_size"] || null }}
                </p>

                <p
                  v-if="
                    property['area'] != 0 &&
                    property['area'] != null &&
                    property['area'] != 'null'
                  "
                >
                  <span style="color: #284858">
                    <i class="fa-solid fa-up-right-and-down-left-from-center"></i> Typical
                    Floor Size (sq):
                  </span>
                  {{
                    property["area"] != 0 &&
                    property["area"] != null &&
                    property["area"] != "null"
                      ? property["area"]
                      : null
                  }}
                </p>

                <p
                  v-if="
                    property['building_height'] != '' &&
                    property['building_height'] != null &&
                    property['building_height'] != 'null'
                  "
                >
                  <span style="color: #284858">
                    <i class="fa-solid fa-building-flag"></i> Building Height:
                  </span>
                  {{ property["building_height"] || null }}
                </p>

                <p
                  v-if="
                    property['floors'] != 0 &&
                    property['floors'] != null &&
                    property['floors'] != 'null'
                  "
                >
                  <span style="color: #284858">
                    <i class="fa-duotone fa-solid fa-layer-group"></i> Floors:
                  </span>
                  {{
                    property["floors"] != 0 &&
                    property["floors"] != null &&
                    property["floors"] != "null"
                      ? property["floors"]
                      : null
                  }}
                </p>

                <p>
                  <span style="color: #284858">
                    <i class="fa-duotone fa-solid fa-tags"></i> Price:
                  </span>
                  ${{ property["min_price"] }} - ${{ property["max_price"] }}
                </p>

                <p
                  v-if="
                    property.capacity &&
                    property.capacity !== '' &&
                    property.capacity !== null
                  "
                >
                  <span style="color: #284858">
                    <i class="fa-solid fa-people-group"></i> Occupancy Limit :
                  </span>
                  {{ property.capacity }}
                </p>

                <p>
                  <span style="color: #284858">
                    <i class="fa-solid fa-file-contract"></i> Minimum Contract Length:
                  </span>
                  {{ property["min_length"] }}
                  {{
                    property["contract_type"] &&
                    property["contract_type"] != "" &&
                    property["contract_type"] != "null"
                      ? property["contract_type"]
                      : "months"
                  }}
                </p>

                <p>
                  <span style="color: #284858">
                    <i class="fa-solid fa-file-contract"></i> Maximum Contract Length:
                  </span>
                  {{ property["max_length"] }}
                  {{
                    property["max_contract_length_type"] &&
                    property["max_contract_length_type"] != "" &&
                    property["max_contract_length_type"] != "null"
                      ? property["max_contract_length_type"]
                      : "months"
                  }}
                </p>

                <p>
                  <span style="color: #284858">
                    <i class="fa-solid fa-clock-rotate-left"></i> Availability:
                  </span>
                  {{ displayAvailability }}
                </p>
              </div>

              <div class="title_sidebar">
                <div class=""></div>
              </div>

              <p
                v-if="
                  property['terms_and_condition_file'] &&
                  property['terms_and_condition_file'] !== '' &&
                  property['terms_and_condition_file'] != 'null'
                "
              >
                <a
                  :href="`${baseAPI}${this.property.terms_and_condition_file}`"
                  target="_blank"
                >
                  <i class="fa-solid fa-file-circle-check"></i>
                  Terms and conditions
                </a>
              </p>
              <div class="listingDetails__facilities__group">
                <div
                  class="row"
                  v-if="
                    property['property_video'] &&
                    property['property_video'] != '' &&
                    property['property_video'] != 'null'
                  "
                >
                  <div class="col prop_vid_link">
                    <a
                      class="videoLink"
                      :href="property['video_link']['url']"
                      target="_blank"
                      style="color: #268cab"
                      ><i class="fa-solid fa-link"></i> Property Video Link</a
                    >
                  </div>
                </div>
                <div class="row">
                  <div class="col-xl-12 col-lg-12">
                    <div class="listingDetails__facilities__type">Location</div>
                    <google-map
                      :markers="markers"
                      styleData=" height: 300px"
                    ></google-map>
                  </div>
                  <div class="col-xl-7 col-md-6" style="margin-top: 20px">
                    <div class="listingDetails__facilities__type">Amenities</div>
                    <div class="d-flex flex-wrap w100">
                      <ul class="listingDetails__facilities__list w50">
                        <li v-for="item in showAmenities" :key="item">
                          <i :class="amenities[item - 1]['icon_name']"></i>
                          <!-- <i class="fas fa-utensils"></i> -->
                          {{ amenities[item - 1]["name"] }}
                        </li>
                        <li
                          v-for="item in amenities.length"
                          :key="item"
                          v-if="item > showAmenities"
                        >
                          <i :class="amenities[item - 1]['icon_name']"></i>
                          <!-- <i class="fas fa-utensil-spoon"></i> -->
                          {{ amenities[item - 1]["name"] }}
                        </li>
                      </ul>

                      <!-- <ul
                        class="listingDetails__facilities__list w50"
                        v-if="amenities.length > 0"
                      >
                        <li
                          v-for="item in amenities.length"
                          :key="item"
                          v-if="item > showAmenities"
                        >
                          <i :class="amenities[item - 1]['icon_name']"></i>
                          {{ amenities[item - 1]["name"] }}
                        </li>
                      </ul> -->
                    </div>
                  </div>

                  <div
                    class="col-xl-4 col-md-6"
                    v-if="nearByAmenities.length > 0"
                    style="margin-top: 20px"
                  >
                    <div class="listingDetails__facilities__type">Nearby</div>
                    <ul class="listingDetails__facilities__list">
                      <li v-for="(item, index) in nearByAmenities" :key="index">
                        <i :class="item['amenities']['icon_name']"></i>
                        {{ item["amenities"]["name"] }}
                        <span class="colorGray--darker">({{ item["distance"] }} mi)</span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div class="listingDetails__table" v-if="addOptions && addOptions.length > 0">
              <div class="table--responsive">
                <table class="table" v-if="additionalOptionData.length > 0">
                  <thead>
                    <tr>
                      <td>Additional Options</td>
                      <td>Basic Layout</td>
                      <td>Standard Layout</td>
                      <td>Premium Layout</td>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(addOption, index) in additionalOptionData" :key="index">
                      <td class="align-center">
                        {{ addOption.options.name }}
                      </td>

                      <td v-if="addOption.options.basic == 1">
                        <i class="fas fa-check-circle colorGreen"></i>
                      </td>
                      <td v-else-if="addOption.options.basic">
                        {{ addOption.options.basic }}
                      </td>
                      <td v-else>-</td>

                      <td v-if="addOption.options.standard == 1">
                        <i class="fas fa-check-circle colorGreen"></i>
                      </td>
                      <td v-else-if="addOption.options.standard">
                        {{ addOption.options.standard }}
                      </td>
                      <td v-else>-</td>

                      <td v-if="addOption.options.premium == 1">
                        <i class="fas fa-check-circle colorGreen"></i>
                      </td>
                      <td v-else-if="addOption.options.premium">
                        {{ addOption.options.premium }}
                      </td>
                      <td v-else>-</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div class="listingDetails__colRight col-lg-4">
            <div class="inner-sticky-datails">
              <!-- <div class="widget widget--date">
              <div class="search__field fieldFloating">
                <div class="d-flex">
                  <datepicker
                    ref="picker"
                    @focusout.native="close"
                    class="formControl"
                    style="border-radius: 0"
                    v-model="start_date"
                  >
                  </datepicker>
                  <button
                    v-if="start_date !== property['available_from']"
                    class="date-reset"
                    @click="
                      start_date = property['available_from'] | getFormatDate
                    "
                  >
                    X
                  </button>
                </div>
                <label class="fieldFloating__label" style="top: 15px"
                  >Start Date</label
                >
              </div>
              <div class="fieldFloating fieldDate">
                <datepicker
                  ref="picker"
                  open
                  class="formControl"
                  style="border-radius: 0"
                  v-model="start_date"
                >
                </datepicker>

                <input
                  type="text"
                  placeholder=" "
                  readonly
                  :value="property['available_from'] | getFormatDate"
                  class="formControl"
                />
                <label class="fieldFloating__label">Start Date</label>
                <button
                  v-if="start_date !== property['available_from']"
                  class="date-reset"
                  @click="start_date = property['available_from']"
                  style="position: absolute; right: 20px; top: 30px"
                >
                  X
                </button>
                <i
                  v-if="start_date == property['available_from']"
                  class="fas fa-calendar-alt"
                ></i>
                <button
                  v-else
                  class="date-reset"
                  @click="start_date = property['available_from']"
                  style="position: absolute; right: 20px; top: 30px"
                >
                  X
                </button>
              </div>
            </div>
            <div class="widget" v-if="contractLengths.length > 0">
              <div class="widget__title">Contract Length</div>
              <div
                class="eRadio"
                v-for="(contL, index) in contractLengths"
                :key="index"
              >
                <input
                  type="radio"
                  v-model="contractLength"
                  :id="'contractLength' + contL['id']"
                  :value="contL"
                />
                <label :for="'contractLength' + contL['id']" class="d-flex">
                  {{
                    contL
                      ? contL["contract_length"]["length"]
                      : null | getLength
                  }}
                  <strong class="mlAuto" v-if="contL.price"
                    >+ ${{ contL["price"] }}</strong
                  >
                </label>
              </div>
            </div> -->
              <div class="widget">
                <!-- <div  class="d-flex justify-content-between ">
    <div class="title_sidebar" >
        <div  class="price">
            <h3>$405.88</h3> 
        </div>
      
        <div  class="plan_paid fw-500">monthly/person</div>
    </div>
    <div  class="">
        <div  class="contracts">
            
        </div>
    </div>
</div> -->
                <!-- <div class="title_sidebar">
  <div class="">
      <div class="contracts fw-500">  </div>
      <div class="layout fw-500"></div>
  </div>
</div> -->

                <!-- <div class="">
  Layout Design
  <span>(Capacity)</span>
</div> -->

                <!-- <div
  class="eRadio"
  v-for="(layout, index) in layoutDesigns"
  :key="index"
>
  <input
    type="radio"
    v-model="layoutDesign"
    :id="'layout' + layout['id']"
    :value="layout"
  />
  <label :for="'layout' + layout['id']" class="d-flex">
    {{ layout["layout_design"]["name"] }}
    <span v-if="layout.capacity">({{ layout.capacity }})</span>
    <strong class="mlAuto" v-if="layout.price"
      >+ ${{ layout["price"] }}</strong
    >
  </label>
</div> -->
              </div>
              <div
                class="widget"
                v-if="additionalOptionData && additionalOptionData.length > 0"
              >
                <div class="widget__title">Additional Options</div>
                <div
                  class="eCheckbox"
                  v-for="item in additionalOptionData.length > 3
                    ? 3
                    : additionalOptionData.length"
                  :key="item"
                >
                  <input
                    type="checkbox"
                    v-model="add_opt"
                    :value="{
                      name: additionalOptionData[item - 1]['options']['name'],
                      id: additionalOptionData[item - 1]['additional_option_id'],
                      price: additionalOptionData[item - 1]['price'],
                    }"
                    :name="
                      'additionalOption' +
                      additionalOptionData[item - 1]['additional_option_id']
                    "
                    :id="
                      'additionalOption' +
                      additionalOptionData[item - 1]['additional_option_id']
                    "
                  />
                  <label
                    :for="
                      'additionalOption' +
                      additionalOptionData[item - 1]['additional_option_id']
                    "
                    class="d-flex"
                  >
                    {{ additionalOptionData[item - 1]["options"]["name"] }}
                    <strong class="mlAuto" v-if="additionalOptionData[item - 1]['price']"
                      >+ ${{ additionalOptionData[item - 1]["price"] }}</strong
                    >
                  </label>
                </div>
                <div class="me" v-if="!isClicked">
                  <a
                    href="javascript:;"
                    class="btn--link"
                    title="View All (+4)"
                    v-if="additionalOptionData.length > 3"
                    @click="isClicked = !isClicked"
                    >View All (+{{ additionalOptionData.length - 3 }})</a
                  >
                </div>

                <div
                  class="eCheckbox"
                  v-for="item in additionalOptionData.length"
                  :key="item"
                  v-if="isClicked && item > 3"
                >
                  <input
                    type="checkbox"
                    v-model="add_opt"
                    :value="additionalOptionData[item - 1]['options']['name']"
                    :name="
                      'additionalOption' +
                      additionalOptionData[item - 1]['additional_option_id']
                    "
                    :id="
                      'additionalOption' +
                      additionalOptionData[item - 1]['additional_option_id']
                    "
                  />
                  <label
                    :for="
                      'additionalOption' +
                      additionalOptionData[item - 1]['additional_option_id']
                    "
                  >
                    {{ additionalOptionData[item - 1]["options"]["name"] }}
                    <strong v-if="additionalOptionData[item - 1]['price']" class="mlAuto"
                      >+ ${{ additionalOptionData[item - 1]["price"] }}</strong
                    >
                  </label>
                </div>
              </div>
              <!-- <div class="row widget widget--total d-flex justify-content-between totalPrice">
<div class="col-md-12">
  <span>Monthly Cost Per Person</span>
  <span style="float: right">${{ totalCost.toFixed(2) }}</span>
</div>
<div class="col-md-12">
  <span><b>Total Monthly Cost</b></span>
  <span>${{ property["min_price"] }} - ${{ property["max_price"] }}</span>
  <span style="float: right">${{ totalCost.toFixed(2) }}</span>
</div>
{{ role }}
</div> -->
              <!-- tabs start -->
              <div class="tabs_main">
                <div class="tabs-tours">
                  <div class="tab">
                    <button
                      class="tablinks active"
                      @click="openCity('persontour', $event)"
                    >
                      In-person Tour
                    </button>
                    <button class="tablinks" @click="openCity('virtualtour', $event)">
                      Virtual Tour
                    </button>
                  </div>

                  <div
                    id="persontour"
                    class="tabcontent"
                    style="display: block"
                    v-if="!showVirtual"
                  >
                    <p class="f14-size">Select a date:</p>

                    <div class="slider_select">
                      <div class="slide_prev" @click="scrollLeft">
                        <i class="fa fa-angle-left" aria-hidden="true"></i>
                      </div>
                      <div class="slider_custom">
                        <div
                          v-for="(day, index) in days"
                          :key="index"
                          :class="{
                            slider_radio_inner: true,
                            active: index === activeIndex,
                          }"
                          @click="setActiveIndex(index, true)"
                          style="cursor: pointer"
                        >
                          <input type="radio" class="radio_slider" />
                          <p class="detail_slider">
                            <span v-html="formatDate(day)"></span>
                          </p>
                        </div>
                      </div>
                      <div class="slide_prev" @click="scrollRight">
                        <i class="fa fa-angle-right" aria-hidden="true"></i>
                      </div>
                    </div>
                    <div class="select_virtua">
                      <!-- <div class="form-group">
        <label>
          Select a time GMT+5:30:
        </label>
        <select class="form-select form-control w-100">
          <option>
            12:00 am
          </option>
          <option>
            01:00 am
          </option>
          <option>
            02:00 am
          </option>
        </select>
      </div> -->
                    </div>
                    <div class="inpersons_tour">
                      <p>
                        Interested in touring this home in-person ? <br />
                        Call our Sales team at
                        <a href="tel:+1 4252439447">+1 (425) 243-9447 </a>to schedule a
                        time.
                      </p>
                    </div>
                    <button
                      class="contect_btn contectUs"
                      title="Contact us"
                      @click="toggleSchedulePopup(true, 'In-person')"
                    >
                      Schedule In-Person tour
                    </button>
                  </div>

                  <div id="virtualtour" class="tabcontent">
                    <p class="f14-size">Select a date:</p>

                    <div class="slider_select">
                      <div class="slide_prev" @click="scrollLeft">
                        <i class="fa fa-angle-left" aria-hidden="true"></i>
                      </div>
                      <div class="slider_custom">
                        <div
                          v-for="(day, index) in days"
                          :key="index"
                          :class="{
                            slider_radio_inner: true,
                            active: index === activeIndex,
                          }"
                          @click="setActiveIndex(index)"
                        >
                          <input type="radio" class="radio_slider" />
                          <p class="detail_slider">
                            <span v-html="formatDate(day)"></span>
                          </p>
                        </div>
                      </div>
                      <div class="slide_prev" @click="scrollRight">
                        <i class="fa fa-angle-right" aria-hidden="true"></i>
                      </div>
                    </div>
                    <div class="select_virtua">
                      <!-- <div class="form-group">
        <label>
          Select a time GMT+5:30:
        </label>
        <select class="form-select form-control w-100">
          <option>
            12:00 am
          </option>
          <option>
            01:00 am
          </option>
          <option>
            02:00 am
          </option>
        </select>
      </div> -->
                      <button
                        class="contect_btn contectUs"
                        title="Contact us"
                        @click="toggleSchedulePopup(true, 'Virtual')"
                      >
                        Schedule Virtual tour
                      </button>
                    </div>
                  </div>
                </div>

                <div class="d-flex bottom-button">
                  <a href="tel:+1 4252439447">+1 (425) 243-9447</a>
                  <a
                    class="contectUs"
                    @click="toggleSchedulePopup(true, 'Request Details')"
                  >
                    Request Details
                  </a>
                </div>
              </div>

              <!-- tabs end -->
              <!-- <button class="buttonload btn btn--blue w100" style="pointer-events: none" v-if="isSubmitting">
Submitting ...
</button>

<button v-if="!isSubmitting && role == 'admin'" class="btn btn--blue w100" title="Get Started"
@click="bookNow">
Get Started
</button> -->
              <!-- <button class="btn btn--blue w100 contectUs" title="Contact us" @click="CHANGE_CONTECT_US_POP_UP(true)">
Contact us
</button> -->

              <div class="">
                <button
                  class="btn contact-us-link contectUs"
                  title="Want more information? Get in touch."
                  @click="toggleSchedulePopup(true, 'Wants more information')"
                >
                  Want more information? Get in touch.
                </button>
              </div>
              <div class="">
                <button
                  class="btn contact-us-link contectUs"
                  title="Broker? See what WeWork has to offer you."
                  @click="
                    toggleSchedulePopup(
                      true,
                      'Broker wants to know what WeWork offering to brokers'
                    )
                  "
                >
                  Broker? See what WeWork has to offer you.
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
    <footer-component></footer-component>
  </div>
</template>

<script>
import HeaderComponent from "@/containers/client/Header";
import FooterComponent from "@/containers/client/Footer";
import { getProperty, getCalculation, scheduleTour } from "@/api/properties";
import { postContract } from "@/api/contracts";
import { getOptions } from "@/api/options";
import Datepicker from "vuejs-datepicker";
// import autocomplete from "./AutoComplete";
// import VueSlider from "vue-slider-component";
import GoogleMap from "./GoogleMap";
import { mapMutations, mapGetters } from "vuex";
// import carousel from "vue-owl-carousel";
import { VueAgile } from "vue-agile";

export default {
  name: "DetailProperty",
  components: {
    HeaderComponent,
    Datepicker,
    // autocomplete,
    GoogleMap,
    // carousel,
    agile: VueAgile,
    FooterComponent,
  },
  data() {
    return {
      isHidden: false,
      // isLandscape: false,
      property: null,
      layoutDesign: null,
      // rdbtnLength: null,
      contractLengths: null,
      layoutDesigns: null,
      contractLength: null,
      amenities: null,
      add_opt: [],
      isClicked: false,
      addOptions: null,
      nearByAmenities: [],
      showAmenities: null,
      markers: null,
      loading: true,
      // slideIndex: 1,
      images: [],
      id: null,
      baseAPI: process.env.VUE_APP_BASE_API,
      // finalPrice: 0,
      isSubmitting: false,
      prevRoute: null,
      perPersonCost: 0,
      totalCost: 0,
      start_date: "",
      isImageDisplay: false,
      sliderIndex: 0,
      maxSliderIndex: 3,
      days: [],
      activeIndex: 0,
      showSchedulePopup: false,
      isSubmitting: false,
      form: {
        name: "",
        email: "",
        phone: "",
        tourType: "",
        propertyId: atob(this.$router.history.current.params.id), // Assuming this is passed as a prop
      },
      showVirtual: false,
      nameError: false,
      emailError: false,
      phoneError: false,
    };
  },
  watch: {
    // 'form.name'(val) {
    //   this.validateName(val);
    // },
    // 'form.email'(val) {
    //   this.validateEmail(val);
    // },
    // 'form.phone'(val) {
    //   this.validatePhone(val);
    // },
    contractLength() {
      this.getCost();
    },
    layoutDesign() {
      this.getCost();
    },
    add_opt() {
      this.getCost();
    },
    start_date() {},
  },
  filters: {
    getLength(data) {
      if (data == 12) {
        return "1 year";
      }
      if (data > 11) {
        return (
          Math.floor(data / 12) +
          " years " +
          (data % 12 > 0 ? (data % 12) + " months" : "")
        );
      } else return data + " months";
    },
    getFormatDate(data) {
      let startDate = data.split("-");
      return startDate[2] + "/" + startDate[1] + "/" + startDate[0];
    },
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.prevRoute = from;
    });
  },
  created() {
    this.loading = true;
    this.id = atob(this.$router.history.current.params.id);
    getOptions(1, "all")
      .then((res) => {
        let data = (res.data && res.data.data) || [];
        this.addOptions = data;
      })
      .catch((err) => {});
    getProperty(this.id)
      // console.log("this.id", this.id)
      .then((res) => {
        this.loading = false;
        this.isImageDisplay = true;
        this.images = res.data.data.images;
        this.isImageDisplay = false;
        let data = (res.data && res.data.data) || [];
        if (data.is_available == 0) {
          // this.$router.push("/");
        }
        this.property = data;
        this.start_date = this.property.available_from;
        this.contractLengths = data["contract_lengths"];
        this.contractLengths.forEach((contL) => {
          if (contL.is_default) {
            this.contractLength = contL;
          } else {
            this.contractLength = this.contractLengths[0];
          }
        });
        this.layoutDesigns = data["layout_designs"];
        if (this.layoutDesigns.length > 0) {
          this.layoutDesign = this.layoutDesigns[0];
        }
        this.amenities = data["amenities"];
        this.nearByAmenities = data["near_by_amenities"];
        this.additionalOptionData = data["additional_options"];
        this.markers = [
          {
            ...this.property,
            latitude: data.latitude,
            longitude: data.longitude,
            // location: data.longitude,
            price: data.price,
          },
        ];
        this.showAmenities =
          this.nearByAmenities.length < this.amenities.length &&
          this.nearByAmenities.length > 2
            ? this.nearByAmenities.length
            : Math.ceil(this.amenities.length / 2);
        this.getCost();
      })
      .catch((err) => {
        this.isImageDisplay = true;
      });
  },
  mounted() {
    // this.showSlides(this.slideIndex);
    let image = this.$refs.image;
    this.generateDays();
  },
  methods: {
    ...mapMutations("popup", ["CHANGE_CONTECT_US_POP_UP"]),
    validateName(name) {
      const trimmedName = name.trim();
      const namePattern = /^[A-Za-z\s]{3,30}$/; // Only alphabetic characters, length between 3 and 30
      if (namePattern.test(trimmedName)) {
        this.nameError = false;
        return true;
      } else {
        this.nameError = true;
        return false;
      }
    },
    validateEmail(email) {
      const trimmedEmail = email.trim();
      const emailPattern = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[c][o][m]$/; // Basic email format
      if (emailPattern.test(trimmedEmail)) {
        this.emailError = false;
        return true;
      } else {
        this.emailError = true;
        return false;
      }
    },
    validatePhone(phone) {
      const trimmedPhone = phone.trim();
      const phonePattern = /^\d{7,16}$/; // Numeric digits only, length between 7 and 16
      if (phonePattern.test(trimmedPhone)) {
        this.phoneError = false;
        return true;
      } else {
        this.phoneError = true;
        return false;
      }
    },
    getFormattedDate(daysToAdd) {
      const date = new Date();
      date.setDate(date.getDate() + daysToAdd);

      const day = String(date.getDate()).padStart(2, "0");
      const month = date.toLocaleString("default", { month: "short" }).toUpperCase();
      const year = date.getFullYear();

      return `${day}-${month}-${year}`;
    },
    openUrl(url) {
      window.open(url, "_blank");
    },
    toggleSchedulePopup(show, type) {
      this.showSchedulePopup = show;
      this.form.tourType = type;
    },
    closeSchedulePopup() {
      this.showSchedulePopup = false;
      this.nameError = false;
      this.emailError = false;
      this.phoneError = false;
      this.form.name = "";
      this.form.email = "";
      this.form.phone = "";
      this.form.tourType = "";
      this.form.propertyId = atob(this.$router.history.current.params.id);
    },
    async submitForm() {
      if (
        this.validateName(this.form.name) &&
        this.validateEmail(this.form.email) &&
        this.validatePhone(this.form.phone)
      ) {
        this.isSubmitting = true;

        let propertyDataToSend = {
          ...this.form,
          title: this.property.title,
          location: this.property.location,
          country: this.property.country,
          pincode: this.property.pincode,
          manager_email: this.property.manager_email,
          scheduled_date:
            this.form.tourType == "In-person" || this.form.tourType == "Virtual"
              ? this.getFormattedDate(this.activeIndex)
              : null,
        };

        await scheduleTour(propertyDataToSend)
          .then((res) => {
            if (res?.data?.success) {
              this.$notify({
                group: "notify",
                type: "success",
                text: res?.data?.message || "Tour request sent successfully.",
              });
            } else {
              this.$notify({
                group: "notify",
                type: "error",
                text: res?.data?.message || "Something went wrong, try again later.",
              });
            }
          })
          .catch((error) => {
            this.$notify({
              group: "notify",
              type: "error",
              text:
                error?.response?.data?.data?.message ||
                "Something went wrong, try again later.",
            });
          })
          .finally(() => {
            this.isSubmitting = false;
            this.closeSchedulePopup();
          });
      } else {
      }
    },
    getCost() {
      const params = {
        property: this.property,
        contractLength: this.contractLength,
        layoutDesign: this.layoutDesign,
      };
      getCalculation(params)
        .then((res) => {
          if (res.data.data) {
            this.perPersonCost = res.data.data.perPerson;
            this.totalCost = res.data.data.totalPrice;
          }
          if (this.add_opt.length > 0) {
            this.add_opt.forEach((data) => {
              if (data.price) {
                this.totalCost += data.price;
                this.perPersonCost = this.totalCost / this.layoutDesign?.capacity;
              }
            });
          }
        })
        .catch((err) => {
          this.$notify({
            group: "notify",
            type: "error",
            text: err,
          });
        });
    },
    bookNow() {
      if (!this.contractLength || !this.layoutDesign || !this.layoutDesign["capacity"]) {
        this.$notify({
          group: "notify",
          type: "error",
          text: "please select any one options from each contract length, layout design",
        });
      } else {
        let conLength = {
          length: this.contractLength["contract_length"]["length"],
          price: this.contractLength["price"],
          id: this.contractLength["contract_length_id"],
        };

        let layoutDesign = {
          name: this.layoutDesign["layout_design"]["name"],
          price: this.layoutDesign["price"],
          id: this.layoutDesign["layout_design_id"],
        };

        if (this.start_date) {
          // debugger;
          let new_date = new Date(this.start_date);
          let year = new_date.getFullYear();
          let date = new_date.getDate();
          let month = new_date.getMonth() + 1;
          this.start_date = year + "-" + month + "-" + date;
          // moment(new Date()).format("DD/MM/YYYY");
          // query.start_date = startDate;
        }

        let data = {
          property_id: this.id,
          start_date: this.start_date,
          contract_length: conLength,
          layout: layoutDesign,
          capacity: this.layoutDesign["capacity"], //this.property["capacity"],
          additionalOptions: this.add_opt,
          final_price: this.totalCost,
          cost_per_person: this.perPersonCost,
          contract_length_price: this.totalCost,
          contract_length_id: this.contractLength.contract_length_id,
          layout_id: this.layoutDesign.layout_design_id,
          layout_price: this.layoutDesign.price,
        };
        this.isSubmitting = true;
        postContract(data)
          .then((res) => {
            this.$router.push("/contract/" + btoa(res.data.data.id));
            this.isSubmitting = false;
          })
          .catch((err) => {
            this.isSubmitting = false;
            this.$notify({
              group: "notify",
              type: "error",
              text: "Something went wrong please try again",
            });
          });
      }
    },
    openCity(cityName, evt) {
      this.showVirtual = !this.showVirtual;
      this.isHidden = true;
      const tabcontent = document.getElementsByClassName("tabcontent");
      for (let i = 0; i < tabcontent.length; i++) {
        tabcontent[i].style.display = "none";
      }
      const tablinks = document.getElementsByClassName("tablinks");
      for (let i = 0; i < tablinks.length; i++) {
        tablinks[i].className = tablinks[i].className.replace("active", "");
      }
      console.log("cityname" + cityName);
      document.getElementById(cityName).style.display = "block";
      console.log("evt", evt);
      evt.target.className += " active";
    },
    scrollLeft() {
      if (this.sliderIndex > 0) {
        this.sliderIndex--; // Decrease the slider index
        this.scrollSlider();
      }
    },
    scrollRight() {
      if (this.sliderIndex < this.maxSliderIndex) {
        this.sliderIndex++; // Increase the slider index
        this.scrollSlider();
      }
    },
    scrollSlider() {
      const sliderContainer = document.querySelector(".slider_custom");
      const scrollAmount = sliderContainer.offsetWidth * this.sliderIndex;
      sliderContainer.scrollTo({
        left: scrollAmount,
        behavior: "smooth",
      });
    },
    generateDays() {
      const currentDate = new Date();
      for (let i = 0; i < 6; i++) {
        const newDate = new Date(currentDate);
        newDate.setDate(currentDate.getDate() + i);
        this.days.push(newDate);
      }
    },
    formatDate(date) {
      const days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
      const months = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ];
      const dayOfWeek = days[date.getDay()];
      const month = months[date.getMonth()];
      const day = date.getDate();
      return `${dayOfWeek}<br>${month} ${day}`;
    },
    setActiveIndex(index, hideVirtual) {
      this.activeIndex = index;
      if (hideVirtual) {
        this.showVirtual = false;
      }
    },
    getDateSuffix(day) {
      if (day % 10 === 1 && day !== 11) {
        return "st";
      } else if (day % 10 === 2 && day !== 12) {
        return "nd";
      } else if (day % 10 === 3 && day !== 13) {
        return "rd";
      } else {
        return "th";
      }
    },
    dateFormat(dateValue) {
      const date = new Date(dateValue);

      const suffixes = ["th", "st", "nd", "rd"];

      const day = date.getDate();
      const suffix = this.getDateSuffix(day);

      const months = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ];

      const month = months[date.getMonth()];
      const year = date.getFullYear();

      return `${day}${suffix} ${month}, ${year}`;
    },
  },
  computed: {
    ...mapGetters(["role"]),
    wrapperRatio() {
      let wrapper = this.$refs.wrapper;
      return wrapper.clientHeight / wrapper.clientWidth;
    },
    displayAvailability() {
      //   if (this.property.available_end !== null) {
      //     return `${this.dateFormat(this.property.available_from)} -  ${this.dateFormat(
      //       this.property.available_end
      //     )}`;
      //   } else {
      //     return `${this.dateFormat(this.property.available_from)}`;
      //   }

      if (this.property.available_from !== null) {
        const today = new Date();
        const availableFrom = new Date(this.property.available_from);

        if (availableFrom <= today) {
          return "Ready for occupancy";
        } else {
          const diffInMonths =
            (availableFrom.getFullYear() - today.getFullYear()) * 12 +
            (availableFrom.getMonth() - today.getMonth());
          const years = Math.floor(diffInMonths / 12);
          const months = diffInMonths % 12;

          let duration = "";
          if (years > 0) duration += `${years} year${years > 1 ? "s" : ""}`;
          if (months > 0)
            duration += `${years > 0 ? " " : ""}${months} month${months > 1 ? "s" : ""}`;

          return `Expected in ${duration}`;
        }
      } else {
        return `${this.dateFormat(this.property.available_from)}`;
      }
    },
  },
};
</script>

<style scoped>
.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.popup-content {
  background: white;
  padding: 20px;
  border-radius: 8px;
  width: 100%;
  max-width: 500px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  position: relative;
}

.popup-title {
  margin-bottom: 20px;
  font-size: 1.5em;
  color: #333;
  text-align: center;
}

.popup-close {
  position: absolute;
  top: 10px;
  right: 10px;
  background: transparent;
  border: none;
  font-size: 1.5em;
  color: #888;
  cursor: pointer;
}

.popup-close:hover {
  color: #333;
}

.popup-form {
  display: flex;
  flex-direction: column;
}

.form-group {
  margin-bottom: 15px;
}

.form-label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
  color: #555;
}

.form-input {
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  box-sizing: border-box;
}

.form-submit,
.form-close {
  width: 100%;
  padding: 10px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1em;
  color: white;
}

.form-submit {
  background-color: #28485e;
  margin-bottom: 10px;
}

.form-submit:hover {
  background-color: #28485e;
}

.form-close {
  background-color: #ccc;
}

.form-close:hover {
  background-color: #999;
}
</style>
<style lang="scss" scope>
.vdp-datepicker .vdp-datepicker__calendar {
  width: auto !important;
}

.fieldFloating .vue-slider {
  margin-top: 12px;
  width: calc(100% + 50px) !important;
  position: absolute;
  left: -25px;
  z-index: 9;
  background: #fff;
  min-height: 50px;
  border-radius: 4px !important;
  padding: 15px 20px !important;
  box-sizing: border-box;
}

.align-center {
  text-align: center !important;
}

a {
  cursor: pointer;
}

// .fade {
//   animation-duration: 0.2s !important;
// }
#extended-img {
  overflow: hidden;
  position: relative;
  height: 100%;
  text-align: center;
}

#extended-img img:not(.contain):not(.cover) {
  height: 100%;
}

.slideshow-container {
  .prev,
  .agile__nav-button--prev,
  .next,
  .agile__nav-button--next {
    background-color: black;
    border: 0;
    color: white;
  }

  .agile__nav-button--prev {
    left: 0;
  }

  .agile__nav-button--next {
    right: 0;
  }
}

.listingDetails__colRight {
  .btn:not(.contact-us-link) {
    margin-bottom: 20px;

    @media (min-width: 1921px) {
      margin-bottom: 1.024vw;
    }
  }
}

.videoLink {
  color: blue;
}

#spinnerImage {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: -1;
}

.slideshow-container > div > div,
.slideshow-container > div,
.agile__slides,
.agile__slides > div {
  height: 100%;
}

.agile__slide {
  height: 100%;
}

.title_sidebar {
  margin-bottom: 10px;
}

.title_sidebar p {
  color: #666666;
  font-size: 14px;
}

.title_sidebar h3 {
  font-size: 15px;
  color: #000;
  font-weight: 500;
  margin-bottom: 3px;
}

// tabs

.tab {
  overflow: hidden;
}

/* Style the buttons inside the tab */
.tab button {
  background-color: inherit;
  float: left;
  border: none;
  outline: none;
  cursor: pointer;
  padding: 14px 14px;
  transition: 0.3s;
  font-size: 13px;
  text-transform: uppercase;
  font-weight: 600;
}

/* Change background color of buttons on hover */

/* Create an active/current tablink class */
.tab button.active {
  border-bottom: 2px solid #28485e;
}

/* Style the tab content */
.f14-size {
  font-size: 14px;
}

.tabcontent {
  display: none;
  padding: 6px 12px;
  border-top: none;
}

.inpersons_tour p {
  font-size: 14px;
  line-height: 20px;
  color: #666666;
}

.bottom-button a {
  width: 50%;
  text-align: center;
}

.bottom-button a {
  width: 50%;
  text-align: center;
  color: #28485e;
  font-size: 14px;
  font-weight: 600;
}

.bottom-button a:hover {
  color: #fff;
  background-color: #28485e;
}

.bottom-button a {
  width: 50%;
  text-align: center;
  color: #28485e;
  font-size: 14px;
  font-weight: 600;
  padding: 10px 20px;
}

.tabs_main {
  border-radius: 5px;
  background-color: #fff;
  padding: 1px;
  box-shadow: 0px 0px 21px #dcd7d7;
  border-radius: 8px;
}

.select_virtua select {
  width: 100%;
  background: #fff;
  border: 1px solid #bdbdbd;
  height: 38px;
  border-radius: 9px;
  padding: 0 12px;
  margin: 10px 0;
}

.contect_btn {
  background: #28485e;
  color: #fff;
  width: 100%;
  margin-top: 40px;
  border: 0 !important;
  padding: 10px;
  border-radius: 10px;
  font-size: 14px;
  height: 46px;
  text-transform: uppercase;
  font-weight: 600;
}

.select_virtua {
  font-size: 14px;
}

.slider_radio_inner {
  border-radius: 4px;
  outline: 1px solid grey;
  text-align: center;
  font-size: 13px;
  padding: 10px 23px;
  margin: 4px 7px;
  position: relative;
}

.slider_custom {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  overflow-x: scroll;
  width: 100%;
  overflow-y: hidden;
  height: 85px;
  margin: 0 13px;
}

.slider_select {
  margin-bottom: 20px;
}

.radio_slider {
  cursor: pointer;
  display: block;
  width: 100%;
  height: 100%;
  overflow: hidden;
  opacity: 0;
}

.detail_slider {
  position: absolute;
  top: 21px;
  width: 100%;
  left: 0;
  right: 0;
  font-weight: 600;
}

.slider_radio_inner.active {
  background: #fff;
  outline: 2px solid #28485e;
  box-shadow: inset 4px -4px 17px -11px #28485e;
}

.slider_select {
  display: flex;
  align-content: center;
}

.slide_prev {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
}

.slide_prev {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  background: #28485e;
  color: #fff;
  width: 42px;
  height: 27px;
  border: 0;
  border-radius: 5px;
}

.widget {
  margin-bottom: 5px;
}

.avail_text {
  color: #000 !important;
  font-weight: 500;
  font-size: 15px;
}

.time_main {
  margin-top: 6px;
}

.listingDetails__facilities {
  box-shadow: 0px 0px 21px #dcd7d7 !important;
  border-radius: 8px;
}
.inner-sticky-datails {
  position: sticky;
  top: 66px;
}
.fieldFloating {
  box-shadow: 0px 0px 21px #dcd7d7 !important;
  border-radius: 8px;
}

.listingDetails__facilities__list li {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  border: 1px solid #d5d5d5;
  padding: 9px;
  border-radius: 4px;
  margin: 0px 10px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  font-family: "Inter-Regular";
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  font-size: 1.4rem;
  position: relative;
}
.listingDetails__facilities__list li i[class*="fa-"] {
  color: #268cab;
  font-size: 19px;
  width: 16px;
  margin-right: 15px;
  text-align: center;
}
#extended-img img:not(.contain):not(.cover) {
  height: 100%;
  width: 100%;
  object-fit: cover;
}
.slideshow-container .prev,
.slideshow-container .agile__nav-button--prev,
.slideshow-container .next,
.slideshow-container .agile__nav-button--next {
  background-color: #ffffff;
  border: 0;
  color: #28485e;
  border-radius: 43px;
  padding: 6px 8px;
  margin: 0px 15px;
}
.listingDetails__facilities h1 {
  font-family: "Inter-SemiBold";
  font-style: normal;
  text-transform: capitalize;
  font-weight: normal;
  font-size: 25px;
  font-size: 1.8rem !important;
}
.row-jet {
  display: grid;
  grid-template-columns: repeat(
    2,
    1fr
  ); /* Two columns, each taking up 50% of the width */
  gap: 10px; /* Adjust the gap between items if needed */
}
@media screen and (min-width: 320px) and (max-width: 976px) {
  .row-jet {
    display: grid;
    grid-template-columns: repeat(
      1,
      1fr
    ); /* Two columns, each taking up 50% of the width */
    gap: 10px; /* Adjust the gap between items if needed */
  }
}
</style>
<style lang="scss">
// Import Main styles for this application
@import "../../assets/scss/client";
</style>
